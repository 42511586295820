<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading || verifying" />
      <error-notification :show="assignedError">
        <span>{{ $t("notifications.auth.team_assigned") }}</span>
      </error-notification>
    </div>
    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      :initial-country="initialCountry"
      @selected-country="adjustCountry"
      @selected-district="adjustDistrict"
    />
    <div class="row">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.code.value"
          :disabled="loading"
          :label="$t('teams.inputs.codeInput')"
          :error="!!form.code.errors.length"
          :error-messages="translatedErrors(form.code.errors)"
          @blur="validate('code')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedLeader"
          :label="$t('teams.inputs.leaderInput')"
          :options="leadersList"
          :loading="isLoading.leaders"
          :no-options-text="$t('layout.empty')"
          :disabled="loading || !hasDistrict || !hasCountry"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="email"
          v-model="form.email.value"
          :disabled="loading"
          :label="$t('layout.form.emailInput')"
          :error="!!form.email.errors.length"
          :error-messages="translatedErrors(form.email.errors)"
          @blur="validate('email')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedStatus"
          :label="$t('layout.form.status')"
          :options="statusList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedResponsible"
          :label="$t('teams.inputs.responsable')"
          :options="leadermembersList"
          :loading="isLoading.responsable"
          :no-options-text="$t('layout.empty')"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="init-input"
          :config="{ mode: 'multiple' }"
          v-model="teamDateResponsible"
          :label="$t('teams.inputs.dateresponsable')"
        />
      </div>
    </div>

  <div class="row">
    <div class="flex xs12">
      <h6>{{ $t("teams.inputs.equipementSelectInput") }}</h6>

      <div class="vue-select-image-container" style="margin-top: 24px;">
        <vue-select-image
          ref="userImageSelect"
          root-class="equipement-style"
          :data-images="equipements"
          :is-multiple="false"
          :use-label="true"
          :selected-images="selectedEquipement"
          @onselectimage="selectEquipement"
        ></vue-select-image>
      </div>
    </div>
  </div>

    <div class="row">

      <div class="flex xs12">
        <va-button
          color="primary"
          @click="redirectToInventoryRequests"
        >
          <text-loading :loading="loading">
            {{ $t("layout.form.request.equipment") }}
          </text-loading>
        </va-button>
        <va-button
          color="primary"
          @click="submit"
          :disabled="
            loading || verifying || !hasLeader || !hasCountry || !hasDistrict
          "
        >
          <text-loading :loading="loading" icon="fa fa-save">
            {{ $t("layout.form.save") }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { validatorMixin } from "@/services/validator";
import { initial } from "lodash";
const LocationBar = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Bars/Location");
const VueSelectImage = () =>
  import(/* webpackPrefetch: true */ "vue-select-image");

export default {
  name: "teams-form",
  components: {
    VueSelectImage,
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    team: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  mixins: [validatorMixin],
  data() {
    return {
      verifying: false,
      assignedError: false,
      initialStatus: "",
      isLoading: {
        leaders: false,
        responsible: false,
      },
      isError: {
        leaders: false,
        responsible: false,
      },
      form: {
        code: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        email: {
          value: "",
          validate: {
            required: true,
          },
          errors: [],
        },
        equipment_isnotworking: {
          value: false,
          validate: {
            required: true,
          },
          errors: [],
        },
      },
      locationFilters: ["regions", "countries", "districts"],
      initialDistrict: null,
      initialCountry: null,
      selectedCountry: "",
      selectedDistrict: "",
      leadersList: [],
      leadermembersList: [],
      responsibleList: [],
      selectedLeader: "",
      selectedStatus: "",
      selectedEquipement: [],
      selectedLanguages: [],
      list: [],
      selectedResponsible: "",
      teamDateResponsible: "",
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentLocale"]),
    hasCountry() {
      return this.selectedCountry !== null;
    },
    hasDistrict() {
      return this.selectedDistrict !== null;
    },
    hasLeader() {
      return this.selectedLeader !== null;
    },
    hasResponsible() {
      return this.selectedResponsible !== null;
    },
    statusList() {
      if (this.list.length === 0) {
        return [
          { id: 0, name: this.$t("teams.status.removed"), value: -1 },
          { id: 1, name: this.$t("teams.status.new"), value: 0 },
          { id: 2, name: this.$t("teams.status.renewed"), value: 1 },
          { id: 3, name: this.$t("teams.status.Registered"), value: 2 },
        ];
      }

      return this.list;
    },

    notValid() {
      if (!this.selectedLanguages.length) {
        this.showToast(this.$t("layout.forms.language_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
      }
      if (!this.selectedEquipement.length) {
        this.showToast(this.$t("notifications.errors.equipment_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
      }

      return this.noTeamData;
    },
    equipements() {
      const e = [
        {
          id: "1",
          src: "/img/equipements/full_equipement.jpg",
          alt: this.$t("teams.equipements.backpack"),
        },
        {
          id: "2",
          src: "/img/equipements/semi_equipement.jpg",
          alt: this.$t("teams.equipements.solar"),
        },
        {
          id: "3",
          src: "/img/equipements/large_equipement.jpg",
          alt: this.$t("teams.equipements.large"),
        },
        {
          id: "5",
          src: "/img/equipements/tablet.jpeg",
          alt: this.$t("teams.equipements.handheld"),
        },
        {
          id: "4",
          src: "/img/equipements/no_equipement.png",
          alt: this.$t("teams.equipements.none"),
        },
      ];
      return e;
    },
  },
  watch: {
    team(val) {
      this.setTeam(val);

      const equipmentValue = val.equipment;
      if (equipmentValue !== null) {
        this.selectedEquipement = this.equipements.filter((e) =>
          equipmentValue.includes(e.id)
        );
      } else {
        this.selectedEquipement = [this.equipements[4]];
      }

      this.$nextTick(() => {
        const component = this.$refs.userImageSelect;
        if (component && typeof component.setInitialSelection === "function") {
          component.setInitialSelection();
        } else {
          console.error(
            "El componente userImageSelect no está disponible o no tiene el método setInitialSelection"
          );
        }
      });
      this.form.equipment_isnotworking.value = val.equipment_isnotworking;
    },
    selectedLeader(val) {
      if (this.team.leader && this.team.leader.id === val.id) return;
      this.checkLeader(val);
    },
    async currentLocale(val) {
      await this.$nextTick();
      this.validateAll();
    },
    "currentUser.permission"(val) {
      this.initialData();
    },
  },
  created() {
    this.initialData();
    this.$nextTick(() => {
      this.validateAll();
    });
  },
  mounted() {
    if (this.team) {
      this.setTeam(this.team);
    }
  },
  methods: {
    setTeam(val) {
      console.log('Current', val.status);
      this.leadermembersList = val.users;
      this.setFormData(val);

      if (val.equipment) {
        this.selectedEquipement = this.equipements.filter((e) =>
          val.equipment.includes(e.id)
        );
      } else {
        this.selectedEquipement = [this.equipements[4]];
      }

      if (val.region_id) {
        this.selectedRegion = val.region_id;
      }
      if (val.district) {
        this.selectedDistrict = val.district;
        this.initialDistrict = val.district;
        if (val.district.country) {
          this.selectedCountry = val.district.country;
        }
      }

      if (val.country) {
        if (!this.selectedCountry) {
          this.selectedCountry = val.country;
        }
        if (!this.initialDistrict) {
          this.initialCountry = val.country;
        }
      }
      if (val.leader) {
        this.selectedLeader = val.leader;
        if (!val.email) {
          this.setFormValue("email", val.leader.email);
        }
      }
      if (Number.isInteger(val.status)) {
        const status = this.statusList.find((s) => s.value === val.status);
        this.selectedStatus = status || "";
        this.initialStatus = status;
      }

      if (val.id) {
        this.$nextTick();
        this.validateAll();
      }
      if (val.responsible) {
        this.selectedResponsible = val.responsible;
      }
      if (val.date_responsible) {
        this.teamDateResponsible = val.date_responsible;
      }
    },
    initialData() {
      if (this.team) {
        this.setTeam(this.team);
      }

      if (this.currentUser.can("Users", "index")) {
        this.asyncFindLeader();
      }
    },

    selectEquipement(selected) {
      this.selectedEquipement = [selected];
    },

    async asyncFindLeader(query = "") {
      await this.$http.get(`users/leaders${query}`).then((response) => {
        this.leadersList = response.data.data || [];
      });
      if (this.isError.leaders) {
        this.asyncFindLeader(query);
      }
    },

    async asyncUsers(query, type, route) {
      this.isLoading[type] = true;
      this.isError[type] = false;

      if (!query.includes("&sort=")) {
        query += "&sort=name";
      }
      if (!query.includes("&direction=")) {
        query += "&direction=asc";
      }

      let response = false;
      try {
        response = await this.$http.get(`/${route}?group=${query}`);
      } catch (err) {
        this.isLoading[type] = false;
        this.isError[type] = true;
        return;
      }

      this[type + "List"] = response.data.data || [];
      this.isLoading[type] = false;
    },

    async checkLeader(user) {
      this.verifying = true;
      this.team.email = user.email;

      let response = false;
      try {
        if (user.id) {
          response = await this.$http.get(`users/ownership/${user.id}?teams`);
        }
      } catch (e) {
        this.verifying = false;
        return;
      }

      this.assignedError = !!response.data.data;
      if (!this.assignedError) {
        this.setFormValue("email", user.email);
        this.form.email.value = user.email;
      }
      this.verifying = false;

      await this.$nextTick();
      this.validateAll();
    },
    async removeLeader() {
      this.assignedError = false;
      this.team.email = null;
      this.selectedLeader = "";

      await this.$nextTick();
      this.validateAll();
    },
    adjustCountry(country) {
      this.selectedCountry = country;
      if (
        this.selectedLeader &&
        this.selectedLeader.country_id !== country.id
      ) {
        this.removeLeader();
      }
      if (
        this.selectedDistrict &&
        this.selectedDistrict.country_id !== country.id
      ) {
        this.selectedDistrict = null;
      }
    },
    adjustDistrict(district) {
      this.selectedDistrict = district;
      if (
        this.selectedLeader &&
        this.selectedLeader.district_id !== district.id
      ) {
        this.removeLeader();
      }
      if (district.id) {
        this.asyncFindLeader("?district_id=" + district.id);
      }
    },
    hasError() {
      let errors = false;

      if (this.selectedCountry === null) {
        this.showToast(this.$t("notifications.errors.country_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
        errors = true;
      }
      if (this.selectedDistrict === null) {
        this.showToast(this.$t("notifications.errors.district_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
        errors = true;
      }
      if (this.selectedLeader === null) {
        this.showToast(this.$t("notifications.errors.leader_needed"), {
          icon: "fa-times",
          position: "top-right",
        });
        errors = true;
      }

      return errors;
    },

    async updateStatusHistory(team, updated_status) {
      const data = {
        status: updated_status,
      }
      try {
        await this.$http.post("teams/history/" + team.id, data);
      } catch (e) {
        this.loading = false;
        return;
      }
    },

    redirectToInventoryRequests() {
      this.$router.push('/admin/inventory/requests/new');
    },

    async submit() {
      const historyStatusMap = {
        '0': [7, 'new'],
        '-1': [5, 'removed'],
        '1': [4, 'renewed'],
        '2': [6, 'registered'],
      }
      const team = this.getFormData(this.team);
      const prp = this.selectedEquipement
        .map((o) => o.id)
        .sort((a, b) => a > b)
        .join();

      team.equipment_isnotworking = this.form.equipment_isnotworking.value
        ? true
        : false;

      team.equipment = prp;

      team.region_id = this.selectedCountry.region_id;
      delete team.region;

      team.country_id = this.selectedCountry.id;
      delete team.country;

      team.district_id = this.selectedDistrict.id;
      delete team.district;

      team.leader_id = this.selectedLeader.id;
      delete team.leader;

      team.status = this.selectedStatus.value;
      if (team.status !== this.initialStatus.value) {
        const mappedStatus = historyStatusMap[team.status];
        this.updateStatusHistory(team, mappedStatus[0]);
      }
      this.initialStatus = this.selectedStatus;

      team.responsible_id = this.selectedResponsible.id;

      team.date_responsible = this.teamDateResponsible;

      if (team.users) {
        delete team.users;
      }

      this.assignedError = false;
      this.$emit("submit", team);
    },
  },
};
</script>
<style lang="scss">
.equipement-style {
  display: flex;
  justify-content: center;

  &__wrapper {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    margin: 0 12px 12px 0;
    float: left;

    @media only screen and (min-width: 1200px) {
      margin-left: 30px;
    }
  }

  &__thumbnail {
    padding: 6px;
    border: 1px solid #dddddd;
    display: block;
    // padding: 4px;
    line-height: 20px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &--selected {
      background: #0088cc;
      color: white;

      .custom-style__img {
        zoom: 1.1;
      }
    }
  }

  &__img {
    -webkit-user-drag: none;
    display: block;
    width: 10vw;
    min-width: 150px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    height: 120px;
    cursor: pointer;
  }

  &__lbl {
    display: block;
    font-weight: bold;
    text-align: center;
  }
}
</style>
